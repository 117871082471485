import React from 'react';
import PageLayout from './PageLayout';
import Hero from '../components/homepage/Hero';
import Features from '../components/homepage/Features';
import Tools from '../components/homepage/Tools';
import { Typography, Row, Col, Button } from 'antd';
import './Homepage.css';

const { Title, Text } = Typography;

const Homepage = () => {
  return (
    <PageLayout fullWidthContent={<Hero />}>
      <div style={{ position: 'relative', marginTop: '0px', zIndex: 1 }}>
        <Tools />

        <div style={{ padding: '48px 24px', background: '#f5f5f5' }}>
          <Title level={2} style={{ marginBottom: '48px', textAlign: 'center' }}>
            Why Use Our Tools
          </Title>
          <Features />
        </div>

        <div className="quick-access" style={{ padding: '48px 24px' }}>
          <Row gutter={[48, 48]} align="middle">
            <Col xs={24} md={12}>
              <Title level={2}>Streamline Your Workflow</Title>
              <Text style={{ fontSize: '16px', display: 'block', marginBottom: '24px' }}>
                Access all your audio and video production tools in one place.
                Increase productivity with our integrated workspace.
              </Text>
              <Button type="primary" size="large">
                View Documentation
              </Button>
            </Col>
            <Col xs={24} md={12}>
              <img 
                src="/images/workspace.jpg"
                alt="Workspace"
                style={{ width: '100%', borderRadius: '12px' }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </PageLayout>
  );
};

export default Homepage;
