import React from 'react';
import { Layout } from 'antd';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

const PageLayout = ({ children, fullWidthContent }) => {
  return (
    <Layout style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', background: '#f5f5f5' }}>
      <Header />
      <Layout.Content style={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column', marginTop: '0px'  }}>
        {fullWidthContent}
        <div style={{ maxWidth: '1800px', width: '100%', margin: '0 auto', padding: '0 0px' }}>
          {children}
        </div>
      </Layout.Content>
      <Footer />
    </Layout>
  );
};

export default PageLayout;
