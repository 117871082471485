import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: '/api',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to add auth token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token refresh
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If error is 401 and we haven't tried to refresh token yet
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refresh = localStorage.getItem('refreshToken');
        if (!refresh) {
          throw new Error('No refresh token available');
        }

        // Make a direct axios call to refresh token instead of using store
        const response = await axios.post('/api/auth/token/refresh/', {
          refresh: refresh
        });

        if (response.status === 200) {
          localStorage.setItem('token', `Bearer ${response.data.access}`);
          originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        // Only clear tokens, let the auth store handle the logout
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        
        // Check specifically for refresh token expiration
        const isRefreshTokenExpired = 
          refreshError.response?.data?.code === 'token_not_valid' &&
          refreshError.response?.data?.detail === 'Token is invalid or expired' &&
          refreshError.response?.status === 401;

        // Only redirect if refresh token is expired and we're not already on login page
        if (isRefreshTokenExpired && window.location.pathname !== '/login') {
          window.location.href = '/login';
        }
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
