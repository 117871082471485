import React, { useState } from "react";
import { Form, Input, Button, Card, Alert, Result } from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import useAuthStore from "../../stores/authStore";
import Logo from "../common/Logo";

const Register = () => {
  const navigate = useNavigate();
  const register = useAuthStore((state) => state.register);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);

  const onFinish = async (values) => {
    setError("");
    setLoading(true);

    const result = await register({
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      password: values.password1,
    });

    setLoading(false);

    if (result.success) {
      setRegistered(true);
    } else {
      setError(result.error);
    }
  };

  if (registered) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          background: "#f0f2f5",
        }}
      >
        <Card style={{ width: 420, padding: "32px 30px" }}>
          <Result
            status="success"
            title="Registration Successful!"
            subTitle={
              <div>
                <p>Please check your email to verify your account.</p>
                <p>Once verified, you can proceed to login.</p>
              </div>
            }
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => navigate("/login")}
              >
                Go to Login
              </Button>,
            ]}
          />
        </Card>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        background: "#f0f2f5",
      }}
    >
      <Card style={{ width: 420, padding: "32px 30px" }}>
        <div style={{ padding: "0 0 32px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 12,
            }}
          >
            <Logo.Favicon size={52} hyperlink={true} />{" "}
          </div>
          <h2 style={{ textAlign: "center", marginBottom: 12 }}>
            Register to {Logo.APP_NAME}
          </h2>
        </div>

        {error && (
          <Alert
            message={error}
            type="error"
            showIcon
            style={{ marginBottom: 16 }}
          />
        )}

        <Form name="register" onFinish={onFinish}>
          <Form.Item
            name="first_name"
            rules={[
              { required: true, message: "Please input your first name!" },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="First Name" />
          </Form.Item>

          <Form.Item
            name="last_name"
            rules={[
              { required: true, message: "Please input your last name!" },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Last Name" />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email!" },
              {
                validator: (_, value) => {
                  if (!value || value.endsWith('@andovar.com')) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Only @andovar.com email addresses are allowed!');
                },
              },
            ]}
          >
            <Input prefix={<MailOutlined />} type="email" placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="password1"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>

          <Form.Item
            name="password2"
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password1") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The passwords do not match!");
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Confirm Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loading}
            >
              Register
            </Button>
          </Form.Item>
        </Form>
        <div style={{ textAlign: "center", marginTop: 16 }}>
          Already have an account? <Link to="/login">Login</Link>
        </div>
      </Card>
    </div>
  );
};

export default Register;
