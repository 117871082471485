import React from 'react';
import { Typography, Row, Col, Card, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import * as Icons from '@ant-design/icons';
import toolsData from '../../data/tools.json';

const { Title } = Typography;

const Tools = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    if (path.startsWith('http')) {
      window.location.href = path;
    } else {
      navigate(path);
    }
  };

  const getIcon = (iconName) => {
    const IconComponent = Icons[iconName];
    return IconComponent ? <IconComponent style={{ fontSize: '48px', color: '#1890ff' }} /> : null;
  };

  return (
    <div className="tools-section" style={{ padding: '48px 24px' }}>
      <Title level={2} style={{ textAlign: 'center', marginBottom: '48px' }}>
        Audio & Video Tools
      </Title>
      <Row gutter={[24, 24]} justify="center">
        {toolsData.tools.map((tool) => (
          <Col xs={24} sm={12} md={8} lg={6} key={tool.key}>
            <Card 
              hoverable 
              style={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                maxWidth: '300px',
                margin: '0 auto'
              }}
              bodyStyle={{ 
                flex: 1, 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <div>
                <div style={{ textAlign: 'center', marginBottom: '16px' }}>
                  {getIcon(tool.icon)}
                </div>
                <Card.Meta
                  title={<div style={{ textAlign: 'center' }}>{tool.title}</div>}
                  description={<div style={{ textAlign: 'center' }}>{tool.description}</div>}
                />
              </div>
              <br/>
              <Button 
                type="primary" 
                style={{ marginTop: 'auto' }} 
                block
                onClick={() => handleClick(tool.path)}
              >
                Launch Tool
              </Button>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Tools;
