// FILE: LoadingScreen.js
import React, { useState, useEffect } from 'react';
import { Progress } from 'antd';
import Logo from '../common/Logo';
import './LoadingScreen.css';

const funnyMessages = [
  "Convincing AI not to take over the world",
  "Generating excuses for missing deadlines",
  "Reticulating splines",
  "Loading unnecessary loading messages",
  "Warming up the hamsters",
  "Breaking the internet",
  "Computing the meaning of life",
  "Making the spinny thing spin",
  "Converting caffeine to code",
  "Downloading more RAM"
];

const getRandomMessage = () => {
  return funnyMessages[Math.floor(Math.random() * funnyMessages.length)];
};

const LoadingScreen = () => {
  const [loadingText, setLoadingText] = useState(() => getRandomMessage());
  const [dots, setDots] = useState('');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Change message every second
    const messageInterval = setInterval(() => {
      setLoadingText(getRandomMessage());
    }, 1500);

    // Animate dots separately
    const dotInterval = setInterval(() => {
      setDots(prev => prev === '...' ? '' : prev + '.');
    }, 200);

    // Simulate progress
    const progressInterval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(progressInterval);
          return 100;
        }
        return prev + 1;
      });
    }, 20);

    return () => {
      clearInterval(messageInterval);
      clearInterval(dotInterval);
      clearInterval(progressInterval);
    };
  }, []);

  // Update the JSX return statement
  return (
    <div className="loading-screen">
      <div className="content-wrapper">
        <div className="logo pulse">
          <Logo iconSize={48} titleLevel={2} />
        </div>

        <div className="spinner">
          <div className="spinner-inner"></div>
        </div>

        <Progress
          percent={progress}
          showInfo={false}
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
          className="progress-bar"
        />

        <div className="loading-text-container">
          <div className="loading-text">{loadingText}</div>
          <span className="loading-dots">{dots}</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;