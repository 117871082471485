import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import PageLayout from './PageLayout';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkToc from 'remark-toc';

const Documentation = () => {
  const [markdownContent, setMarkdownContent] = useState('');
  const [docsList, setDocsList] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState('docs');
  const contentRef = useRef(null);

  useEffect(() => {
    // Fetch the list of documentation files
    fetch('/documentation/index.json')
      .then(response => response.json())
      .then(data => setDocsList(data.files))
      .catch(error => console.error('Error loading docs list:', error));
  }, []);

  useEffect(() => {
    // Load selected documentation file
    fetch(`/documentation/${selectedDoc}.md`)
      .then(response => response.text())
      .then(text => {
        setMarkdownContent(text);
        // Scroll to top when changing documents
        if (contentRef.current) {
          contentRef.current.scrollTop = 0;
        }
      })
      .catch(error => console.error('Error loading documentation:', error));
  }, [selectedDoc]);

  const markdownComponents = {
    code({node, inline, className, children, ...props}) {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <SyntaxHighlighter
          style={tomorrow}
          language={match[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
    a({node, children, href, ...props}) {
      // Handle internal navigation links
      if (href && !href.startsWith('http') && !href.includes('/')) {
        return (
          <span 
            onClick={() => setSelectedDoc(href)}
            style={{cursor: 'pointer', color: '#1890ff', textDecoration: 'underline'}}
            {...props}
          >
            {children}
          </span>
        );
      }
      return (
        <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
          {children}
        </a>
      );
    }
  };

  return (
    <PageLayout>
      <div className="documentation-container" style={{ 
        display: 'flex', 
        // Removed fixed height and overflow: hidden to allow full page scrolling
        backgroundColor: '#f5f5f5',
        marginTop: '0px', 
        padding: '0 0px', 
        marginBottom: '0px'
      }}>
        <div className="docs-sidebar" style={{
          width: '250px',
          height: 'calc(100vh - 88px)',  // Adjust height to fit the viewport
          borderRight: '1px solid #e1e4e8',
          padding: '20px',
          backgroundColor: '#fff',
          boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
          position: 'sticky',
          top: '64px',  // Stick to the top with some offset
          alignSelf: 'flex-start'
        }}>
          <h3 style={{ marginTop: 0, marginBottom: '16px', fontSize: '18px' }}>Documentation</h3>
          {docsList.map(doc => (
            <div
              key={doc.id}
              className={`sidebar-item ${selectedDoc === doc.id ? 'active' : ''}`}
              onClick={() => setSelectedDoc(doc.id)}
              style={{
                padding: '10px 16px',
                cursor: 'pointer',
                borderRadius: '4px',
                marginBottom: '6px',
                backgroundColor: selectedDoc === doc.id ? '#e6f7ff' : 'transparent',
                borderLeft: selectedDoc === doc.id ? '3px solid #1890ff' : '3px solid transparent',
                fontWeight: selectedDoc === doc.id ? '500' : 'normal',
                transition: 'all 0.3s'
              }}
              onMouseOver={(e) => {
                if (selectedDoc !== doc.id) {
                  e.currentTarget.style.backgroundColor = '#f6f8fa';
                }
              }}
              onMouseOut={(e) => {
                if (selectedDoc !== doc.id) {
                  e.currentTarget.style.backgroundColor = 'transparent';
                }
              }}
            >
              {doc.title}
            </div>
          ))}
        </div>
        <div 
          ref={contentRef}
          className="markdown-content" 
          style={{ 
            flex: 1,
            padding: '32px',
            lineHeight: '1.6',
            backgroundColor: '#ffffff',
            margin: '0 auto',
            boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
            width: '100%'
            // Removed height and overflowY constraints
          }}
        >
          <div className="markdown-wrapper markdown-body" style={{
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            color: '#24292e'
          }}>
            <ReactMarkdown 
              remarkPlugins={[remarkGfm, [remarkToc, { tight: true }]]}
              rehypePlugins={[rehypeRaw]}
              components={markdownComponents}
            >
              {markdownContent}
            </ReactMarkdown>
          </div>
        </div>
      </div>
      <style jsx global>{`
        /* Allow page scrolling by removing overflow: hidden */
        html, body, #root {
          height: 100%;
          margin: 0;
          padding: 0;
        }
        
        .markdown-body {
          font-size: 16px;
        }
        
        .markdown-body h1 {
          padding-bottom: 0.3em;
          font-size: 2em;
          border-bottom: 1px solid #eaecef;
          margin-bottom: 16px;
          margin-top: 0;
        }
        
        .markdown-body h2 {
          padding-bottom: 0.3em;
          font-size: 1.5em;
          border-bottom: 1px solid #eaecef;
          margin-top: 24px;
          margin-bottom: 16px;
        }
        
        .markdown-body h3, .markdown-body h4, .markdown-body h5, .markdown-body h6 {
          margin-top: 24px;
          margin-bottom: 16px;
        }
        
        .markdown-body p {
          margin-bottom: 16px;
        }
        
        .markdown-body ul, .markdown-body ol {
          padding-left: 2em;
          margin-bottom: 16px;
        }
        
        .markdown-body li {
          margin-bottom: 4px;
        }
        
        .markdown-body table {
          display: block;
          width: 100%;
          overflow: auto;
          border-collapse: collapse;
          margin: 20px 0;
        }
        
        .markdown-body table th, .markdown-body table td {
          padding: 6px 13px;
          border: 1px solid #dfe2e5;
        }
        
        .markdown-body table tr {
          background-color: #fff;
          border-top: 1px solid #c6cbd1;
        }
        
        .markdown-body table tr:nth-child(2n) {
          background-color: #f6f8fa;
        }
        
        .markdown-body blockquote {
          padding: 0 1em;
          color: #6a737d;
          border-left: 0.25em solid #dfe2e5;
          margin: 16px 0;
        }
        
        .markdown-body hr {
          height: 0.25em;
          padding: 0;
          margin: 24px 0;
          background-color: #e1e4e8;
          border: 0;
        }
        
        .markdown-body code:not([class*="language-"]) {
          padding: 0.2em 0.4em;
          margin: 0;
          font-size: 85%;
          background-color: rgba(27,31,35,0.05);
          border-radius: 3px;
        }
      `}</style>
    </PageLayout>
  );
};

export default Documentation;
