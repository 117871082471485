import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Card, Avatar, Spin, Tag, Button, Form, Input, message, Divider, Space } from 'antd';
import { UserOutlined, EditOutlined, MailOutlined, IdcardOutlined, LockOutlined } from '@ant-design/icons';
import PageLayout from './PageLayout';
import useUserStore from '../stores/userStore';
import useAuthStore from '../stores/authStore';

const { Title, Text } = Typography;

const Profile = () => {
  const { user, loading, fetchUserProfile, updateUserProfile } = useUserStore();
  const { resetPassword } = useAuthStore();
  const [isEditing, setIsEditing] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  const handleSubmit = async (values) => {
    try {
      await updateUserProfile(values);
      setIsEditing(false);
      message.success('Profile updated successfully');
    } catch (error) {
      message.error('Failed to update profile');
    }
  };

  const handlePasswordReset = async (values) => {
    if (values.newPassword !== values.confirmPassword) {
      message.error('Passwords do not match');
      return;
    }

    const result = await resetPassword(user.email, values.newPassword);
    if (result.success) {
      setIsResettingPassword(false);
      passwordForm.resetFields();
    }
  };

  const EditForm = () => (
    <Form
      form={form}
      initialValues={{
        first_name: user?.first_name || '',
        last_name: user?.last_name || ''
      }}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item 
            name="first_name" 
            label="First Name"
            rules={[{ required: true, message: 'Please enter your first name' }]}
          >
            <Input prefix={<IdcardOutlined />} placeholder="Enter first name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            name="last_name" 
            label="Last Name"
            rules={[{ required: true, message: 'Please enter your last name' }]}
          >
            <Input prefix={<IdcardOutlined />} placeholder="Enter last name" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end" gutter={16}>
        <Col>
          <Button onClick={() => setIsEditing(false)}>Cancel</Button>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit">Save Changes</Button>
        </Col>
      </Row>
    </Form>
  );

  const PasswordResetForm = () => (
    <Form
      form={passwordForm}
      onFinish={handlePasswordReset}
      layout="vertical"
    >
      <Form.Item
        name="newPassword"
        label="New Password"
        rules={[
          { required: true, message: 'Please enter new password' },
          { min: 8, message: 'Password must be at least 8 characters' }
        ]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Enter new password" />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        label="Confirm Password"
        rules={[
          { required: true, message: 'Please confirm your password' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Passwords do not match'));
            },
          }),
        ]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Confirm new password" />
      </Form.Item>

      <Row justify="end" gutter={16}>
        <Col>
          <Button onClick={() => setIsResettingPassword(false)}>Cancel</Button>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit">Reset Password</Button>
        </Col>
      </Row>
    </Form>
  );

  if (loading) {
    return (
      <PageLayout>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
          <Spin size="large" />
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <div style={{ padding: '48px 24px', background: '#f5f5f5', minHeight: '0vh' }}>
        <Row justify="center" gutter={[24, 24]}>
          <Col xs={24} sm={20} md={16} lg={12}>
            <Card
              className="profile-card"
              style={{ 
                borderRadius: '12px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <Avatar 
                  size={120} 
                  icon={<UserOutlined />}
                  style={{ 
                    backgroundColor: '#1890ff',
                    marginBottom: '16px',
                    boxShadow: '0 4px 12px rgba(24,144,255,0.15)'
                  }}
                />
                <Title level={2} style={{ margin: '16px 0 4px' }}>
                  {user?.first_name ? `${user.first_name} ${user.last_name}` : user?.email}
                </Title>
                <Space align="center">
                  <MailOutlined />
                  <Text type="secondary">{user?.email}</Text>
                </Space>
                <div style={{ margin: '16px 0' }}>
                  <Tag color={user?.is_verified ? 'success' : 'warning'} style={{ padding: '4px 12px' }}>
                    {user?.is_verified ? 'Verified Email' : 'Pending Email Verification'}
                  </Tag>
                </div>
              </div>

              <Divider />

              {!isEditing && (
                <Button 
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => setIsEditing(true)}
                  block
                  style={{ marginBottom: '24px' }}
                >
                  Edit Profile
                </Button>
              )}

              {isEditing ? (
                <EditForm />
              ) : (
                <Card
                  title="Account Information"
                  size="small"
                  bordered={false}
                  style={{ background: '#fafafa' }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Text strong>First Name</Text>
                      <p>{user?.first_name || 'Not set'}</p>
                    </Col>
                    <Col span={24}>
                      <Text strong>Last Name</Text>
                      <p>{user?.last_name || 'Not set'}</p>
                    </Col>
                    <Col span={24}>
                      <Text strong>Email Address</Text>
                      <p>{user?.email}</p>
                    </Col>
                  </Row>
                </Card>
              )}
            </Card>

            <Card
              className="profile-card"
              style={{ 
                borderRadius: '12px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                marginTop: '24px'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <Typography.Title level={4} style={{ margin: 0 }}>Password Settings</Typography.Title>
                {!isResettingPassword && (
                  <Button 
                    type="primary"
                    icon={<LockOutlined />}
                    onClick={() => setIsResettingPassword(true)}
                  >
                    Reset Password
                  </Button>
                )}
              </div>

              {isResettingPassword && <PasswordResetForm />}
            </Card>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
};

export default Profile;
