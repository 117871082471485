import React, { useEffect } from 'react';
import { Modal, Form, Input, Switch, Select } from 'antd';
import useUserStore from '../../stores/userStore';

const UserForm = ({ open, onCancel, onSubmit, initialValues, title }) => {
  const { groups, fetchGroups } = useUserStore();
  const [form] = Form.useForm();

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (open) {
      form.resetFields();
      if (initialValues) {
        form.setFieldsValue(initialValues);
      }
    }
  }, [open, initialValues, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      onSubmit(values);
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  return (
    <Modal
      open={open}
      title={title}
      okText="Save"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleSubmit}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...initialValues,
          group_ids: initialValues?.groups?.map(g => g.id) || []
        }}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please input email!' },
            { type: 'email', message: 'Please input valid email!' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[{ required: true, message: 'Please input first name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[{ required: true, message: 'Please input last name!' }]}
        >
          <Input />
        </Form.Item>
        {!initialValues && (
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: !initialValues, message: 'Please input password!' }]}
          >
            <Input.Password />
          </Form.Item>
        )}
        <Form.Item
          name="is_staff"
          label="Staff Status"
          valuePropName="checked"
          tooltip="Can access admin panel"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="is_superuser"
          label="Superuser Status"
          valuePropName="checked"
          tooltip="Has all permissions without explicitly assigning them"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="is_active"
          label="Active"
          valuePropName="checked"
          tooltip="Can login to the system"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="is_verified"
          label="Email Verified"
          valuePropName="checked"
          tooltip="Email verification status"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="group_ids"
          label="Groups"
          tooltip="Assign user to groups"
        >
          <Select
            mode="multiple"
            placeholder="Select groups"
            options={groups.map(group => ({
              label: group.name,
              value: group.id
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserForm;
