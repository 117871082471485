import React from 'react';
import { Typography } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import logoImage from '../img/logo.png';
import logoFavicon from '../img/favicon.png';

const { Title } = Typography;

const Logo = ({ 
  iconSize = 24, 
  titleLevel = 3, 
  showText = true, 
  showIcon = true, 
  hyperlink = true,
  imageUrl = Logo.LOGO_URL,
  useFavicon = false
}) => {
  const icon = useFavicon ? (
    <img src={Logo.FAVICON_URL} alt={Logo.APP_NAME} style={{ height: `${iconSize}px` }} />
  ) : imageUrl ? (
    <img src={imageUrl} alt={Logo.APP_NAME} style={{ height: `${iconSize}px` }} />
  ) : (
    showIcon && <AudioOutlined style={{ fontSize: `${iconSize}px`, color: '#1890ff' }} />
  );
  
  const text = showText && (useFavicon || !imageUrl) && (
    <Title level={titleLevel} style={{ margin: 0, color: '#1890ff', fontSize: iconSize * 0.9 }}>
      {Logo.APP_NAME}
    </Title>
  );

  const content = (
    <div style={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: '8px', 
      whiteSpace: 'nowrap',
      cursor: hyperlink ? 'pointer' : 'default'
    }}>
      {icon}
      {text}
    </div>
  );

  return hyperlink ? (
    <Link to="/" style={{ textDecoration: 'none' }}>
      {content}
    </Link>
  ) : content;
};

Logo.APP_NAME = 'Work Andovar';
Logo.LOGO_URL = logoImage;
Logo.FAVICON_URL = logoFavicon;

// Static methods to return different logo variations
Logo.SiteName = ({ hyperlink = false, level = 3, fontSize = 24 }) => (
  <Logo showText={true} showIcon={false} hyperlink={hyperlink} titleLevel={level} iconSize={fontSize} />
);

Logo.Image = ({ hyperlink = false, size = 24 }) => (
  <Logo showText={false} showIcon={false} hyperlink={hyperlink} iconSize={size} imageUrl={Logo.LOGO_URL} />
);

Logo.Favicon = ({ hyperlink = false, size = 24 }) => (
  <Logo showText={false} showIcon={true} hyperlink={hyperlink} iconSize={size} useFavicon={true} />
);

Logo.Full = ({ hyperlink = false, size = 24 }) => (
  <Logo showText={true} showIcon={false} hyperlink={hyperlink} iconSize={size} imageUrl={Logo.LOGO_URL} />
);

Logo.IconText = ({ hyperlink = false, size = 24, level = 3 }) => (
  <Logo 
    showText={true} 
    showIcon={true} 
    hyperlink={hyperlink} 
    iconSize={size} 
    titleLevel={level}
    useFavicon={true} 
  />
);

export default Logo;