import React from 'react';
import { Typography, Button, Space } from 'antd';
import { PlayCircleFilled } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const Hero = () => {
  return (
    <div style={{ 
      padding: '64px 48px', 
      textAlign: 'center', 
      background: 'linear-gradient(180deg, #2C3E50 0%, #3498DB 100%)',
      color: 'white',
      position: 'relative',
      minHeight: '46vh'
    }}>
      <Title style={{ color: 'white', marginTop: '100px', marginBottom: '24px', fontSize: '3.5rem' }}>
        Work Andovar Tools Portal
      </Title>
      <Paragraph style={{ 
        fontSize: '1.2rem',
        color: 'rgba(255,255,255,0.9)',
        marginBottom: '32px',
        maxWidth: '600px',
        margin: '0 auto 32px'
      }}>
        Your all-in-one workspace for audio and video production tools.
        Access everything you need to create, edit, and manage content.
      </Paragraph>
      <Space size="large">
        <Button type="primary" size="large">
          Quick Start Guide
        </Button>
        <Button ghost size="large">
          View Tools
        </Button>
      </Space>
    </div>
  );
};

export default Hero;
