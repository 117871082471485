import React from 'react';
import { Card, Typography } from 'antd';

const { Title, Paragraph } = Typography;

const FeatureCard = ({ icon: Icon, title, description }) => {
  return (
    <Card hoverable style={{ height: '100%', textAlign: 'center' }}>
      <Icon style={{ 
        fontSize: '48px', 
        color: '#1890ff',
        marginBottom: '16px',
        display: 'block'
      }} />
      <Title level={4} style={{ marginBottom: '16px' }}>
        {title}
      </Title>
      <Paragraph style={{ fontSize: '16px', marginBottom: 0 }}>
        {description}
      </Paragraph>
    </Card>
  );
};

export default FeatureCard;
