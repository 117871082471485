import React, { useEffect, useState } from "react";
import { Table, Button, Space, Tag, Popconfirm } from "antd";
import { UserOutlined, PlusOutlined } from '@ant-design/icons';
import useUserStore from "../../stores/userStore";
import UserForm from "./UserForm";
import AdminPageLayout from "./layout/AdminPageLayout";

const Users = () => {
  const { users, usersLoading, fetchUsers, deleteUser, createUser, updateUser } = useUserStore();
  const [formVisible, setFormVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleAdd = () => {
    setEditingUser(null);
    setFormVisible(true);
  };

  const handleEdit = (user) => {
    setEditingUser(user);
    setFormVisible(true);
  };

  const handleSubmit = async (values) => {
    let success;
    if (editingUser) {
      success = (await updateUser(editingUser.id, values)).success;
    } else {
      success = (await createUser(values)).success;
    }
    if (success) {
      setFormVisible(false);
      fetchUsers();
    }
  };

  const handleDelete = async (userId) => {
    if (await deleteUser(userId)) {
      fetchUsers();
    }
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Status",
      key: "status",
      render: (_, record) => (
        <Space direction="vertical" size="small">
          <Tag color={record.is_active ? "green" : "red"}>
            {record.is_active ? "Active" : "Inactive"}
          </Tag>
          {record.is_superuser && <Tag color="gold">Superuser</Tag>}
          {record.is_staff && <Tag color="blue">Staff</Tag>}
          {!record.is_verified && <Tag color="orange">Unverified</Tag>}
        </Space>
      ),
    },
    {
      title: "Groups",
      dataIndex: "groups",
      key: "groups",
      render: (groups) => (
        <Space size={[0, 4]} wrap>
          {groups?.map(group => (
            <Tag key={group.id} color="processing">
              {group.name}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button type="primary" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this user?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <AdminPageLayout
      title="User Management"
      icon={<UserOutlined />}
      subtitle="Manage user accounts and permissions"
      listItems={[
        "View all users in the system",
        "Add new users or modify existing ones",
        "Control user access and permissions"
      ]}
      actions={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAdd}
        >
          Add User
        </Button>
      }
    >
      <Table
        columns={columns}
        dataSource={users.map(user => ({
          ...user,
          key: user.id
        }))}
        pagination={{ pageSize: 10 }}
        loading={usersLoading}
        scroll={{ x: 'max-content' }}
      />
      
      <UserForm
        open={formVisible}
        onCancel={() => setFormVisible(false)}
        onSubmit={handleSubmit}
        initialValues={editingUser}
        title={editingUser ? 'Edit User' : 'Add User'}
      />
    </AdminPageLayout>
  );
};

export default Users;
