import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Navigation from "./Navigation";
import UserMenu from "./UserMenu";
import Logo from "./Logo";

const { Header: AntHeader } = Layout;

const Header = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isNavMenu = windowWidth < 880;
  const isUserCompact = windowWidth < 650;

  return (
    <AntHeader
      style={{
        background: "rgba(255, 255, 255, 0.8)",
        padding: "0 24px",
        position: "sticky",
        top: 0,
        zIndex: 1000,
        width: "100%",
        display: "flex",
        alignItems: "center",
        backdropFilter: "blur(8px)",
        WebkitBackdropFilter: "blur(8px)", // Safari support
        //boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05)',
        //borderBottom: '1px solid rgba(255, 255, 255, 0.3)'
      }}
    >
      <Row 
        justify="space-between" 
        align="middle" 
        style={{ 
          width: "100%",
          flexWrap: "nowrap" // Add this to prevent row wrapping
        }}
      >
        <Col style={{ 
          display: "flex", 
          alignItems: "center",
          flex: "1 1 auto" // Add this to allow the column to grow
        }}>
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Logo hyperlink={true} size={24} level={3} />
          </div>
          {!isNavMenu && (
            <div style={{ 
              marginLeft: "32px",
              flex: "1 1 auto" // Add this to allow the navigation to take available space
            }}>
              <Navigation />
            </div>
          )}
        </Col>
        <Col style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <UserMenu isCompact={isUserCompact} />
          {(isNavMenu || isMobile) && (
            <Button
              type="text"
              icon={<MenuOutlined />}
              onClick={() => setDrawerVisible(true)}
            />
          )}
        </Col>
      </Row>
      {(isNavMenu || isMobile) && (
        <Drawer
          title={<Logo />}
          placement="right"
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          width={480}
          styles={{
            header: {
              padding: "16px 24px",
              borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
            },
          }}
        >
          <Navigation mode="vertical" />
        </Drawer>
      )}
    </AntHeader>
  );
};

export default Header;
