import React from 'react';
import { Layout, Row, Col, Typography, Space } from 'antd';
import { QuestionCircleOutlined, BookOutlined, TeamOutlined } from '@ant-design/icons';

const { Footer } = Layout;
const { Title, Text, Link } = Typography;

const AppFooter = () => {
  return (
    <Footer style={{ 
      background: '#001529', 
      padding: '48px 24px 24px',
      flexShrink: 0,
      width: '100%'
    }}>
      <Row gutter={[48, 32]}>
        <Col xs={24} sm={12} md={8}>
          <Title level={4} style={{ color: 'white' }}>Work Andovar</Title>
          <Text style={{ color: 'rgba(255,255,255,0.8)' }}>
            Internal tools platform for audio and video production. Access all your professional tools in one place.
          </Text>
          <Space style={{ marginTop: 16 }}>
            <Link href="/help" style={{ color: 'white', fontSize: 16 }}>
              <QuestionCircleOutlined /> Help Center
            </Link>
          </Space>
        </Col>
        
        <Col xs={24} sm={12} md={4}>
          <Title level={5} style={{ color: 'white' }}>Tools</Title>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {[
              'SVO Platform',
              'Cost Calculator',
              'Voice Cloning',
              'Audio Editor'
            ].map(item => (
              <li key={item} style={{ margin: '8px 0' }}>
                <Link href={`/tools/${item.toLowerCase().replace(/\s+/g, '-')}`} style={{ color: 'rgba(255,255,255,0.8)' }}>
                  {item}
                </Link>
              </li>
            ))}
          </ul>
        </Col>

        <Col xs={24} sm={12} md={4}>
          <Title level={5} style={{ color: 'white' }}>Resources</Title>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {[
              'Documentation',
              'API Reference',
              'Training Videos',
              'Best Practices'
            ].map(item => (
              <li key={item} style={{ margin: '8px 0' }}>
                <Link href={`/resources/${item.toLowerCase().replace(/\s+/g, '-')}`} style={{ color: 'rgba(255,255,255,0.8)' }}>
                  {item}
                </Link>
              </li>
            ))}
          </ul>
        </Col>

        <Col xs={24} sm={12} md={8}>
          <Title level={5} style={{ color: 'white' }}>Support</Title>
          <Space direction="vertical" size={12}>
            <Link href="/support/ticket" style={{ color: 'rgba(255,255,255,0.8)' }}>
              <BookOutlined /> Submit Support Ticket
            </Link>
            <Link href="/support/training" style={{ color: 'rgba(255,255,255,0.8)' }}>
              <TeamOutlined /> Request Training
            </Link>
            <Text style={{ color: 'rgba(255,255,255,0.8)' }}>
              Support Hours: Mon-Fri, 9AM-6PM (GMT+7)
            </Text>
            <Text style={{ color: 'rgba(255,255,255,0.8)' }}>
              Emergency Support: support@andovar.com
            </Text>
          </Space>
        </Col>
      </Row>
      <div style={{ 
        marginTop: 48, 
        textAlign: 'center', 
        borderTop: '1px solid rgba(255,255,255,0.1)', 
        paddingTop: 24 
      }}>
        <Text style={{ color: 'rgba(255,255,255,0.6)' }}>
          © {new Date().getFullYear()} Work Andovar - Internal Use Only
        </Text>
      </div>
    </Footer>
  );
};

export default AppFooter;
