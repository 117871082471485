import React from 'react';
import { Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Icons from '@ant-design/icons';
import toolsData from '../../data/tools.json';
import { HomeOutlined, ToolOutlined } from '@ant-design/icons';

const Navigation = ({ mode = "horizontal" }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getIcon = (iconName) => {
    const IconComponent = Icons[iconName];
    return IconComponent ? <IconComponent /> : null;
  };

  const toolsSubMenu = toolsData.tools
    .filter(tool => tool.inNav)
    .map(tool => ({
      key: tool.path,
      icon: getIcon(tool.icon),
      label: tool.title,
      isExternal: tool.isExternal
    }));

  const items = [
    {
      key: '/',
      icon: <HomeOutlined />,
      label: 'Dashboard',
    },
    {
      key: 'service',
      icon: <ToolOutlined />,
      label: 'Tools',
      children: toolsSubMenu
    },
  ];

  const handleMenuClick = ({ key, domEvent }) => {
    const menuItem = toolsSubMenu.find(item => item.key === key);
    
    if (menuItem && menuItem.isExternal) {
      window.location.href = key;
    } else if (key !== 'service') {
      navigate(key);
    }
  };

  return (
    <Menu
      mode={mode}
      selectedKeys={[location.pathname]}
      items={items}
      onClick={handleMenuClick}
      style={{ 
        flex: mode === 'horizontal' ? 1 : 0,
        minWidth: mode === 'horizontal' ? '400px' : 0, // Add minimum width for horizontal mode
        border: 'none',
        backgroundColor: 'transparent',
        overflowX: 'visible' // Prevent horizontal overflow hiding
      }}
    />
  );
};

export default Navigation;
