import React from 'react';
import { Card, Row, Col, Typography, Grid } from 'antd';
import Logo from '../common/Logo';

const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const WelcomeBanner = ({ 
    title,
    subtitle,
    listItems = [],
}) => {
    const screens = useBreakpoint();

    return (
        <Card
            bodyStyle={{ 
                padding: screens.md ? '24px' : '12px',
                background: 'linear-gradient(135deg, #1890ff 0%, rgb(131, 74, 211) 100%)',
                borderRadius: '8px',
                position: 'relative'
            }}
        >
            <Row>
                <Col span={24}>
                    <Title level={2} style={{ color: 'white', margin: 0 }}>
                        {title}
                    </Title>
                    {subtitle && (
                        <Paragraph style={{ color: 'white', marginTop: '16px' }}>
                            {subtitle}
                        </Paragraph>
                    )}
                    {listItems.length > 0 && (
                        <ul style={{ color: 'white', paddingLeft: '20px', marginRight: '140px' }}>
                            {listItems.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    )}
                </Col>
            </Row>
        </Card>
    );
};

export default WelcomeBanner;