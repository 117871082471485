import React, { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import queryString from 'query-string';
import Homepage from './Pages/Homepage';

import Login from './components/auth/Login';
import LoginModal from './components/auth/LoginModal';
import RegisterModal from './components/auth/RegisterModal';
import Register from './components/auth/Register';
import EmailVerification from './components/auth/EmailVerification';
import ProtectedStaffRoute from './components/auth/ProtectedStaffRoute';
import ProtectedAndovarEmployeeRoute from './components/auth/ProtectedAndovarEmployeeRoute';
import ProtectedSuperUserRoute from './components/auth/ProtectedSuperUserRoute';

import About from './Pages/About';
import Contact from './Pages/Contact';
import AdminDashboard from './Pages/AdminDashboard';
import Profile from './Pages/Profile';
import Documentation from './Pages/Documentation';
import 'antd/dist/reset.css';
import useAuthStore from './stores/authStore';
import UnauthorizedPage from './Pages/UnauthorizedPage';

const AppContent = () => {
  const location = useLocation();
  const { initialize, googleLogin } = useAuthStore();

  const handleInitialization = useCallback(async () => {
    const values = queryString.parse(location.search);
    const code = values.code;
    
    if (code) {
      await googleLogin(code);
    } else {
      initialize();
    }
  }, [location.search, initialize, googleLogin]);

  useEffect(() => {
    handleInitialization();
  }, [handleInitialization]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/profile" element={<Profile />} />
        <Route
          path="/AdminDashboard/*"
          element={
            <ProtectedSuperUserRoute>
              <AdminDashboard />
            </ProtectedSuperUserRoute>
          }
        />
        <Route path="/api/auth/register/account-confirm-email/:key" element={<EmailVerification />} />
        <Route path="/verify-email/:token" element={<EmailVerification />} />
        <Route
          path="/documentation"
          element={
            <ProtectedStaffRoute>
              <Documentation />
            </ProtectedStaffRoute>
          }
        />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
      </Routes>
    </>
  );
};

const App = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <AppContent />
        <LoginModal />
        <RegisterModal />
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;
