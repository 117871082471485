import React from 'react';
import { Button, Space, Typography, Dropdown, Menu } from 'antd';
import { UserOutlined, LogoutOutlined, ProfileOutlined, DashboardOutlined } from '@ant-design/icons';
import useAuthStore from '../../stores/authStore';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

const UserMenu = ({ isCompact }) => {
  const { isLoggedIn, logout, showLoginModal } = useAuthStore();
  const navigate = useNavigate();

  const getUserData = () => {
    const token = localStorage.getItem('token')?.replace('Bearer ', '');
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        return {
          email: payload.name || payload.email,
          is_staff: payload.is_staff,
          is_superuser: payload.is_superuser
        };
      } catch (error) {
        console.error('Error decoding token:', error);
        return { email: '', is_staff: false, is_superuser: false };
      }
    }
    return { email: '', is_staff: false, is_superuser: false };
  };

  const getInitials = (name) => {
    return name
      .split(/[ @]/)
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const userData = getUserData();

  const userMenu = (
    <Menu
      style={{
        background: "#fff",
        borderRadius: "12px",
        boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
      }}
      items={[
        ...(userData.is_superuser ? [{
          key: "admin",
          icon: <DashboardOutlined />,
          label: "Admin Dashboard",
          onClick: () => navigate("/AdminDashboard"),
        }] : []),
        {
          key: "profile",
          icon: <ProfileOutlined />,
          label: "Profile",
          onClick: () => navigate("/profile"),
        },
        {
          key: "logout",
          icon: <LogoutOutlined />,
          label: "Logout",
          onClick: () => {
            logout();
            // Force navigation after state update
            setTimeout(() => {
              navigate('/', { replace: true });
            }, 0);
          },
        },
      ]}
    />
  );

  const dropdownStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    margin: '12px 0',
    height: '46px',
    padding: '0 12px',
    borderRadius: '40px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    background: '#f0f2f5',
    maxWidth: '250px',
    whiteSpace: 'nowrap',
  };

  const avatarStyle = {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    background: '#1890ff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    color: 'white',
    textTransform: 'uppercase',
  };

  const emailStyle = {
    fontSize: '12px',
    fontWeight: 500,
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  return (
    <Space>
      {isLoggedIn ? (
        <Dropdown overlay={userMenu} trigger={["click"]} placement="bottomRight">
          <div style={dropdownStyle}>
            <div style={avatarStyle}>
              {getInitials(userData.email)}
            </div>
            {!isCompact && (
              <>
                <Text style={emailStyle}>
                  {userData.email}
                </Text>
                <span style={{ color: '#1890ff', fontSize: '12px', flexShrink: 0 }}>▼</span>
              </>
            )}
          </div>
        </Dropdown>
      ) : (
        <Text 
          onClick={showLoginModal}
          style={{
            cursor: 'pointer',
            color: '#1890ff',
            fontSize: '14px',
            fontWeight: 500,
          }}
        >
          Log In
        </Text>
      )}
    </Space>
  );
};

export default UserMenu;
