import React from "react";
import { Card, Space, Grid } from "antd";
import WelcomeBanner from "../WelcomeBanner";

const { useBreakpoint } = Grid;

const AdminPageLayout = ({
  title,
  icon,
  subtitle,
  listItems = [],
  actions,
  children,
  extra,
}) => {
  const screens = useBreakpoint();

  return (
    <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "24px",
        }}
      >
        {/* Full width banner section */}
        <div style={{ marginBottom: "24px" }}>
          <WelcomeBanner
            icon={icon}
            title={title}
            subtitle={subtitle}
            listItems={listItems}
          />
        </div>

        {/* Main content section */}
        {actions && (
          <div style={{ marginBottom: "24px" }}>
            <Space wrap>{actions}</Space>
          </div>
        )}

        {extra && <div style={{ marginBottom: "24px" }}>{extra}</div>}

        <div style={{ flex: 1, overflowY: "auto" }}>{children}</div>
      </div>
    </div>
  );
};

export default AdminPageLayout;
