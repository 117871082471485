import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Layout,
  Menu,
  Typography,
  Space,
  Grid,
  Button,
} from "antd";
import {
  UserOutlined,
  DashboardOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import Dashboard from "../components/admin/Dashboard";
import Categories from "../components/admin/Categories";
import Users from "../components/admin/Users";
import Logo from "../components/common/Logo";
import LoadingScreen from "../components/loadingScreen/LoadingScreen";
import useAuthStore from '../stores/authStore';
import { jwtDecode } from "jwt-decode";
import UserMenu from "../components/common/UserMenu";

const { Header, Content, Sider } = Layout;
const { useBreakpoint } = Grid;

const AdminDashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({
    email: "",
    username: "",
    is_staff: false,
    Email: "",
    Name: "",
    Surname: "",
    Gender: "",
  });
  const BASE_URL = process.env.PUBLIC_URL;
  const logout = useAuthStore(state => state.logout);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserData({
          email: decoded.email,
          username: decoded.email.split('@')[0], // Simple username from email
          is_staff: decoded.is_staff,
        });
      } catch (error) {
        console.error('Error decoding token:', error);
        logout();
        navigate('/');
      }
    } else {
      logout();
      navigate('/');
    }
    setLoading(false);
  }, [navigate, logout]);

  // Add this useEffect to handle initial navigation
  useEffect(() => {
    if (location.pathname === '/AdminDashboard') {
      navigate('/AdminDashboard/dashboard');
    }
  }, [location.pathname, navigate]);

  const renderContent = () => {
    switch (location.pathname) {
      case "/AdminDashboard/dashboard":
        return <Dashboard userData={userData} />;
      case "/AdminDashboard/categories":
        return <Categories />;
      case "/AdminDashboard/users":
        return <Users />;
      default:
        return <Dashboard userData={userData} />;
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  const handleMenuClick = (key) => {
    navigate(key);
  };

  return (
    <Layout style={{ minHeight: "100vh", height: "100vh", overflow: "hidden" }}>
      <Header
        style={{
          padding: "0 16px",
          background: "#fff",
          borderBottom: "1px solid #f0f0f0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Space>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{ fontSize: "16px", width: 64, height: 64 }}
          />
          <Logo.Favicon 
            size={30}
            showText={false}
            level={5}
            style={{ marginRight: 8 }}
            hyperlink={true}
          />
          <Typography.Title level={4} style={{ margin: 0 }}>
            Admin Dashboard
          </Typography.Title>
        </Space>
        <UserMenu />
      </Header>

      <Layout>
        <Sider
          width={200}
          collapsible
          collapsed={collapsed}
          breakpoint="lg"
          collapsedWidth={screens.xs ? 0 : 80}
          trigger={null} // Add this line to remove the trigger
          onBreakpoint={(broken) => {
            setCollapsed(broken);
          }}
          style={{
            background: "#fff",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              padding: collapsed ? "16px 0" : "16px 26px",
              paddingTop: collapsed ? "28px" : "24px",
              textAlign: collapsed ? "center" : "left",  // Changed to left when not collapsed
              flexShrink: 0,
              overflow: 'hidden',
              display: 'flex',
              justifyContent: collapsed ? 'center' : 'flex-start',  // Changed to flex-start when not collapsed
              alignItems: 'center'
            }}
          >
            {collapsed ? (
              <Logo.Favicon 
                size={30}
                hyperlink={false}
              />
            ) : (
              <Logo.SiteName 
                hyperlink={false}
              />
            )}
          </div>
          <Menu
            mode="inline"
            defaultSelectedKeys={[location.pathname === '/AdminDashboard' ? '/AdminDashboard/dashboard' : location.pathname]}
            selectedKeys={[location.pathname === '/AdminDashboard' ? '/AdminDashboard/dashboard' : location.pathname]}
            onClick={({ key }) => handleMenuClick(key)}
            style={{
              borderRight: 0,
              flex: 1, // Takes remaining space
            }}
          >
            <Menu.Item
              key="/AdminDashboard/dashboard"
              icon={<DashboardOutlined />}
              onClick={() => navigate("/AdminDashboard/dashboard")}
            >
              Dashboard 
            </Menu.Item>

            <Menu.Item 
              key="/AdminDashboard/categories" 
              icon={<AppstoreOutlined />}
            >
              Categories
            </Menu.Item>

            <Menu.Item 
              key="/AdminDashboard/users" 
              icon={<UserOutlined />}
            >
              Users
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout
          style={{
            padding: screens.md ? "24px" : "12px",
            height: "98%",
            overflow: "auto", // Enable scrolling for content
          }}
        >
          <Content style={{ height: "100%" }}>{renderContent()}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AdminDashboard;
