import React, { useState } from "react";
import { Modal, Form, Input, Button, Divider, Alert } from "antd";
import { UserOutlined, LockOutlined, GoogleOutlined } from "@ant-design/icons";
import useAuthStore from "../../stores/authStore";
import Logo from "../common/Logo"; // added import

const LoginModal = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isLoginModalVisible, hideLoginModal, login, switchToRegister } =
    useAuthStore();
  const [form] = Form.useForm();

  const handleGoogleLogin = () => {
    const clientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const callBackURI = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
    window.location.replace(
      `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${callBackURI}&prompt=consent&response_type=code&client_id=${clientID}&scope=openid%20email%20profile&access_type=offline&hd=andovar.com`
    );
  };

  const handleCancel = () => {
    setError(null); // Clear errors when modal is closed
    hideLoginModal();
  };

  const onFinish = async (values) => {
    setError(null); // Clear previous errors
    setLoading(true);
    const result = await login(values);
    if (result.success) {
      form.resetFields();
    } else {
      setError(result.error);
    }
    setLoading(false);
  };

  return (
    <Modal
      title={
        <>
          <div style={{ padding: "24px 0 0" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 24,
              }}
            >
              <Logo.Favicon size={52} hyperlink={true} />{" "}
            </div>
            <h2 style={{ textAlign: "center", marginBottom: 0 }}>
              Login to {Logo.APP_NAME}
            </h2>
          </div>
        </>
      }
      width={420}
      open={isLoginModalVisible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      bodyStyle={{ padding: "32px 35px 42px" }}
    >
      {error && (
        <Alert
          message={error}
          type="error"
          showIcon
          style={{ marginBottom: 16 }}
        />
      )}
      <Form form={form} name="login" onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "Please enter a valid email!" },
            {
              validator: (_, value) => {
                if (!value || value.endsWith('@andovar.com')) {
                  return Promise.resolve();
                }
                return Promise.reject('Only @andovar.com email addresses are allowed!');
              },
            },
          ]}
        >
          <Input prefix={<UserOutlined />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>

      <Divider>Or</Divider>

      <Button
        icon={<GoogleOutlined />}
        onClick={handleGoogleLogin}
        style={{ width: "100%" }}
        disabled={loading}
      >
        Login with Google
      </Button>
      <div style={{ textAlign: "center", marginTop: 16 }}>
        Don't have an account?{" "}
        <Button type="link" onClick={switchToRegister} style={{ padding: 0 }}>
          Register now
        </Button>
      </div>
    </Modal>
  );
};

export default LoginModal;
