import { create } from 'zustand';
import { message } from 'antd';
import axiosInstance from '../utils/axiosInstance';

const useAuthStore = create((set, get) => ({
  isLoggedIn: !!localStorage.getItem('token'),
  userEmail: '',
  isLoginModalVisible: false,
  isRegisterModalVisible: false,

  initialize: () => {
    const token = localStorage.getItem('token');
    if (token && !get().isLoggedIn) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        set({ userEmail: payload.email, isLoggedIn: true });
      } catch (error) {
        console.error('Error decoding token:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        set({ isLoggedIn: false, userEmail: '' });
      }
    }
  },

  showLoginModal: () => set({ isLoginModalVisible: true }),
  hideLoginModal: () => set({ isLoginModalVisible: false }),
  showRegisterModal: () => set({ isRegisterModalVisible: true }),
  hideRegisterModal: () => set({ isRegisterModalVisible: false }),

  switchToRegister: () => set({ isLoginModalVisible: false, isRegisterModalVisible: true }),
  switchToLogin: () => set({ isLoginModalVisible: true, isRegisterModalVisible: false }),

  login: async (values) => {
    try {
      const response = await axiosInstance.post('/auth/token/', {
        email: values.email,
        password: values.password
      });

      localStorage.setItem('token', `Bearer ${response.data.access}`);
      localStorage.setItem('refreshToken', response.data.refresh);
      const payload = JSON.parse(atob(response.data.access.split('.')[1]));
      set({ isLoggedIn: true, userEmail: payload.email, isLoginModalVisible: false });
      message.success(`Welcome back, ${payload.email}!`);
      return { success: true };
    } catch (error) {
      return { success: false, error: error.response?.data?.error || 'Login failed. Please try again.' };
    }
  },

  register: async (userData) => {
    try {
      await axiosInstance.post('/auth/register/', userData);
      message.success('Registration successful! Please check your email to verify your account.');
      return { success: true };
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Registration failed';
      return { success: false, error: errorMessage };
    }
  },

  emailVerification: async (token) => {
    try {
      await axiosInstance.post('/auth/verify-email/', { token });
      message.success('Email verified successfully! You can now login.');
      return true;
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Email verification failed';
      message.error(errorMessage);
      return false;
    }
  },

  googleLogin: async (code) => {
    if (!localStorage.getItem('token')) {
      try {
        const response = await axiosInstance.post('/auth/google/', { code });
        
        localStorage.setItem('token', `Bearer ${response.data.access}`);
        localStorage.setItem('refreshToken', response.data.refresh);
        
        const payload = JSON.parse(atob(response.data.access.split('.')[1]));
        set({ 
          isLoggedIn: true, 
          userEmail: payload.email,
          isLoginModalVisible: false 
        });
        
        message.success(`Welcome ${payload.email}!`);
        return true;
      } catch (error) {
        message.error('Google login failed');
        return false;
      }
    }
    return false;
  },

  refreshToken: async () => {
    try {
      const refresh = localStorage.getItem('refreshToken');
      if (!refresh) return false;

      const response = await axiosInstance.post('/auth/token/refresh/', {
        refresh: refresh
      });

      if (response.status === 200) {
        localStorage.setItem('token', `Bearer ${response.data.access}`);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
    }
  },

  logout: (navigate) => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    set({ isLoggedIn: false, userEmail: '' });
    if (navigate) {
      navigate('/');
    }
  },

  setLoginSuccess: (data) => {
    set({ 
      isLoggedIn: true,
      userEmail: data.user.email, // Make sure we're extracting email from response
    });
  },

  resetPassword: async (email, newPassword) => {
    try {
      const response = await axiosInstance.post('/auth/reset-password/', {
        email,
        new_password: newPassword
      });
      message.success('Password reset successful');
      return { success: true };
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Password reset failed';
      message.error(errorMessage);
      return { success: false, error: errorMessage };
    }
  },
}));

export default useAuthStore;