import React, { useState } from "react";
import { Modal, Form, Input, Button, Alert } from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import useAuthStore from "../../stores/authStore";
import Logo from "../common/Logo";

const RegisterModal = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);
  const { isRegisterModalVisible, hideRegisterModal, register, switchToLogin } = useAuthStore();
  const [form] = Form.useForm();

  const handleCancel = () => {
    setError("");
    setRegistered(false);
    form.resetFields();
    hideRegisterModal();
  };

  const onFinish = async (values) => {
    setError("");
    setLoading(true);

    const result = await register({
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      password: values.password1,
    });

    if (result.success) {
      setRegistered(true);
      form.resetFields();
    } else {
      setError(result.error);
    }
    setLoading(false);
  };

  return (
    <Modal
      title={
        <>
          <div style={{ padding: "24px 0 0" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 24,
              }}
            >
              <Logo.Favicon size={52} hyperlink={true} />
            </div>
            <h2 style={{ textAlign: "center", marginBottom: 0 }}>
              Register to {Logo.APP_NAME}
            </h2>
          </div>
        </>
      }
      width={420}
      open={isRegisterModalVisible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      bodyStyle={{ padding: "32px 35px 42px" }}
    >
      {registered ? (
        <Alert
          message="Registration Successful!"
          description={
            <div>
              <p>Please check your email to verify your account.</p>
              <p>Once verified, you can proceed to login.</p>
            </div>
          }
          type="success"
          showIcon
          action={
            <Button type="primary" onClick={handleCancel}>
              Close
            </Button>
          }
        />
      ) : (
        <>
          {error && (
            <Alert
              message={error}
              type="error"
              showIcon
              style={{ marginBottom: 16 }}
            />
          )}

          <Form form={form} name="register" onFinish={onFinish}>
            <Form.Item
              name="first_name"
              rules={[
                { required: true, message: "Please input your first name!" },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="First Name" />
            </Form.Item>

            <Form.Item
              name="last_name"
              rules={[
                { required: true, message: "Please input your last name!" },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Last Name" />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input your email!" },
                { type: "email", message: "Please enter a valid email!" },
                {
                  validator: (_, value) => {
                    if (!value || value.endsWith('@andovar.com')) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Only @andovar.com email addresses are allowed!');
                  },
                },
              ]}
            >
              <Input prefix={<MailOutlined />} type="email" placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="password1"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item
              name="password2"
              rules={[
                { required: true, message: "Please confirm your password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password1") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("The passwords do not match!");
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Confirm Password"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Register
              </Button>
            </Form.Item>
          </Form>
          <div style={{ textAlign: "center", marginTop: 16 }}>
            Already have an account?{" "}
            <Button 
              type="link" 
              onClick={switchToLogin} 
              style={{ padding: 0 }}
            >
              Login
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default RegisterModal;
