import React from 'react';
import { Row, Col } from 'antd';
import { ApiOutlined, TeamOutlined, RocketOutlined } from '@ant-design/icons';
import FeatureCard from './FeatureCard';

const featuresData = [
  {
    icon: ApiOutlined,
    title: 'Integrated Workflow',
    description: 'Seamlessly connect all tools in your production pipeline'
  },
  {
    icon: TeamOutlined,
    title: 'Team Collaboration',
    description: 'Share projects and work together in real-time'
  },
  {
    icon: RocketOutlined,
    title: 'Fast Processing',
    description: 'High-performance cloud processing for all your tasks'
  }
];

const Features = () => {
  return (
    <Row gutter={[24, 24]} justify="center">
      {featuresData.map((feature, index) => (
        <Col xs={24} sm={12} md={8} key={index}>
          <FeatureCard {...feature} />
        </Col>
      ))}
    </Row>
  );
};

export default Features;
