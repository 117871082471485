import { create } from 'zustand';
import { message } from 'antd';
import axiosInstance from '../utils/axiosInstance';

const useUserStore = create((set) => ({
  user: null, // { id, email, first_name, last_name, is_verified }
  loading: false,
  error: null,
  users: [],
  usersLoading: false,
  usersCount: 0,
  groups: [],

  fetchUserProfile: async () => {
    set({ loading: true });
    try {
      const { data } = await axiosInstance.get('/users/me/');
      set({ user: data, loading: false, error: null });
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },

  updateUserProfile: async (userData) => {
    set({ loading: true });
    try {
      const { data } = await axiosInstance.patch('/users/me/', userData);
      set({ user: data, loading: false, error: null });
    } catch (error) {
      set({ error: error.response?.data?.message || 'Failed to update user profile', loading: false });
    }
  },

  fetchUsers: async () => {
    set({ usersLoading: true });
    try {
      const { data } = await axiosInstance.get('/users/list/');
      set({ users: data, usersLoading: false });
    } catch (error) {
      message.error('Failed to fetch users');
      set({ usersLoading: false });
    }
  },

  fetchUsersCount: async () => {
    try {
      const { data } = await axiosInstance.get('/users/count/');
      set({ usersCount: data.count });
      return data.count;
    } catch (error) {
      console.error('Error fetching users count:', error);
      return 0;
    }
  },

  deleteUser: async (userId) => {
    try {
      await axiosInstance.delete(`/users/${userId}/`);
      message.success('User deleted successfully');
      set(state => ({
        users: state.users.filter(user => user.id !== userId)
      }));
      return true;
    } catch (error) {
      message.error('Failed to delete user');
      return false;
    }
  },

  createUser: async (userData) => {
    try {
      const { data } = await axiosInstance.post('/users/list/', userData);
      set(state => ({
        users: [...state.users, data]
      }));
      message.success('User created successfully');
      return { success: true, data };
    } catch (error) {
      message.error(error.response?.data?.message || 'Failed to create user');
      return { success: false, error };
    }
  },

  updateUser: async (userId, userData) => {
    try {
      const { data } = await axiosInstance.patch(`/users/${userId}/`, userData);
      set(state => ({
        users: state.users.map(user => 
          user.id === userId ? { ...user, ...data } : user
        )
      }));
      message.success('User updated successfully');
      return { success: true, data };
    } catch (error) {
      message.error(error.response?.data?.message || 'Failed to update user');
      return { success: false, error };
    }
  },

  fetchGroups: async () => {
    try {
      const { data } = await axiosInstance.get('/users/groups/');
      set({ groups: data });
      return data;
    } catch (error) {
      message.error('Failed to fetch groups');
      return [];
    }
  },

  clearUser: () => {
    set({ user: null, loading: false, error: null });
  },
}));

export default useUserStore;
