import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const ProtectedStaffRoute = ({ children }) => {
  try {
    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    
    return decoded?.is_staff ? children : <Navigate to="/unauthorized" replace />;
  } catch (error) {
    return <Navigate to="/unauthorized" replace />;
  }
};

export default ProtectedStaffRoute;
