import React from 'react';
import { Row, Col, Card, Table, Space, Statistic, Tag, Grid } from 'antd';
import { 
    DashboardOutlined, 
    ShoppingOutlined, 
    DollarOutlined, 
    InboxOutlined, 
    UserOutlined,
    CheckCircleOutlined 
} from '@ant-design/icons';
import AdminPageLayout from './layout/AdminPageLayout';
import useUserStore from '../../stores/userStore';

const { useBreakpoint } = Grid;

const Dashboard = ({ userData }) => {
    const screens = useBreakpoint();
    const [containerWidth, setContainerWidth] = React.useState(0);
    const containerRef = React.useRef(null);
    const [orders, setOrders] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const { usersCount, fetchUsersCount } = useUserStore();

    // Add resize observer to track container width
    React.useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            setContainerWidth(entries[0].contentRect.width);
        });
        
        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => observer.disconnect();
    }, []);

    React.useEffect(() => {
        fetchUsersCount();
    }, []); // Run once on mount

    React.useEffect(() => {
        const fetchOrders = async () => {
            setLoading(true);
            try {
                // Mock data - replace with actual API endpoint
                const mockOrders = [
                    {
                        id: '1',
                        orderDate: '2024-03-20 14:30',
                        total: 299.99,
                        status: 'Processing',
                        items: 3
                    },
                    {
                        id: '2',
                        orderDate: '2024-03-19 09:15',
                        total: 149.99,
                        status: 'Shipped',
                        items: 2
                    }
                ];
                setOrders(mockOrders);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
            setLoading(false);
        };

        if (userData?.ID) {
            fetchOrders();
        }
    }, [userData?.ID]);

    const getRevenueStats = () => {
        return orders.reduce((total, order) => total + order.total, 0);
    };

    const getPendingOrders = () => {
        return orders.filter(order => order.status === 'Processing').length;
    };

    const getTotalOrders = () => {
        return orders.length;
    };

    const orderColumns = [
        {
            title: 'Order ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Date',
            dataIndex: 'orderDate',
            key: 'orderDate',
            render: (text) => (
                <Space>
                    <ShoppingOutlined />
                    {text}
                </Space>
            )
        },
        {
            title: 'Items',
            dataIndex: 'items',
            key: 'items'
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (amount) => `$${amount.toFixed(2)}`
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <Tag color={status === 'Shipped' ? 'green' : 'blue'}>
                    <CheckCircleOutlined /> {status}
                </Tag>
            )
        }
    ];

    return (
        <AdminPageLayout
            title={`Welcome back, ${userData.username}!`}
            icon={<DashboardOutlined />}
            subtitle="Here's your store overview"
            listItems={[
                "Track orders and revenue",
                "Manage inventory",
                "View customer insights"
            ]}
        >
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Card bodyStyle={{ padding: screens.md ? '24px' : '12px' }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={6}>
                            <Card bordered={false}>
                                <Statistic
                                    title="Pending Orders"
                                    value={getPendingOrders()}
                                    prefix={<ShoppingOutlined />}
                                    valueStyle={{ color: '#1890ff' }}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} sm={6}>
                            <Card bordered={false}>
                                <Statistic
                                    title="Total Orders"
                                    value={getTotalOrders()}
                                    prefix={<InboxOutlined />}
                                    valueStyle={{ color: '#52c41a' }}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} sm={6}>
                            <Card bordered={false}>
                                <Statistic
                                    title="Revenue"
                                    value={getRevenueStats()}
                                    prefix={<DollarOutlined />}
                                    valueStyle={{ color: '#722ed1' }}
                                    precision={2}
                                    
                                />
                            </Card>
                        </Col>
                        <Col xs={24} sm={6}>
                            <Card bordered={false}>
                                <Statistic
                                    title="Total Users"
                                    value={usersCount}
                                    prefix={<UserOutlined />}
                                    valueStyle={{ color: '#faad14' }}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Card>

                <Card 
                    title={
                        <Space>
                            <ShoppingOutlined />
                            Recent Orders
                        </Space>
                    } 
                >
                    <Table 
                        columns={orderColumns}
                        dataSource={orders}
                        pagination={false}
                        size="middle"
                        loading={loading}
                    />
                </Card>
            </Space>
        </AdminPageLayout>
    );
};

export default Dashboard;