import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Spin, Result } from "antd";
import useAuthStore from "../../stores/authStore";
import Logo from "../common/Logo";

const EmailVerification = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const emailVerification = useAuthStore((state) => state.emailVerification);
  const [verifying, setVerifying] = useState(true);
  const [success, setSuccess] = useState(false);
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const verifyEmail = async () => {
      if (!token) {
        setSuccess(false);
        setVerifying(false);
        return;
      }
      const result = await emailVerification(token);
      setSuccess(result);
      setVerifying(false);

      if (result) {
        const timer = setInterval(() => {
          setCountdown((prev) => {
            if (prev <= 1) {
              clearInterval(timer);
              navigate("/login");
              return 0;
            }
            return prev - 1;
          });
        }, 1000);

        return () => clearInterval(timer);
      }
    };

    verifyEmail();
  }, [token, emailVerification, navigate]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        background: "#f0f2f5",
      }}
    >
      <Card style={{ width: 420, padding: "32px 60px" }}>
        <div style={{ padding: "0 0 32px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 12,
            }}
          >
            <Logo.Favicon size={52} hyperlink={true} />{" "}
          </div>
          <h2 style={{ textAlign: "center", marginBottom: 12 }}>
            Email Verification
          </h2>
        </div>

        {verifying ? (
          <div style={{ textAlign: "center", padding: "24px 0" }}>
            <Spin size="large" />
            <p style={{ marginTop: "16px" }}>Verifying your email...</p>
          </div>
        ) : (
          <Result
            status={success ? "success" : "error"}
            title={
              success ? "Email Verified Successfully!" : "Verification Failed"
            }
            subTitle={
              success
                ? `You will be redirected to login page in ${countdown} seconds...`
                : "Please try again or contact support if the problem persists."
            }
          />
        )}
      </Card>
    </div>
  );
};

export default EmailVerification;
