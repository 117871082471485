import React from 'react';
import { Typography, Row, Col, Card } from 'antd';
import PageLayout from './PageLayout';

const { Title, Paragraph } = Typography;

const Contact = () => {
  return (
    <PageLayout>
      <div style={{ padding: '24px', background: '#f0f2f5', minHeight: 'calc(100vh - 64px)' }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '24px' }}>
          <Card>
            <Title level={2} style={{ marginBottom: '24px', textAlign: 'center' }}>
              Contact Us
            </Title>
            <Row justify="center">
              <Col xs={24} sm={20} md={16}>
                <Paragraph style={{ fontSize: '16px', lineHeight: '1.6' }}>
                  Get in touch with us at contact@happysun.com or call us at +1 234 567 890...
                </Paragraph>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </PageLayout>
  );
};

export default Contact;
