import React from "react";
import { Table, Button, Space } from "antd";
import { AppstoreOutlined, PlusOutlined } from '@ant-design/icons';
import AdminPageLayout from "./layout/AdminPageLayout";

const Categories = () => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Actions",
      key: "actions",
      render: () => (
        <Space>
          <Button type="primary">Edit</Button>
          <Button danger>Delete</Button>
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: '1',
      name: 'Sample Category 1',
      description: 'Description 1',
    },
    {
      key: '2',
      name: 'Sample Category 2',
      description: 'Description 2',
    },
  ];

  return (
    <AdminPageLayout
      title="Categories"
      icon={<AppstoreOutlined />}
      subtitle="Manage product categories"
      listItems={[
        "Organize products into categories",
        "Create and modify category structure",
        "Manage category hierarchy"
      ]}
      actions={
        <Button
          type="primary"
          icon={<PlusOutlined />}
        >
          Add Category
        </Button>
      }
    >
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 10 }}
      />
    </AdminPageLayout>
  );
};

export default Categories;